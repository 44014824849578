import React from 'react';
import { Link } from 'gatsby';
import Sidebar1 from '../../images/sidebar1.png';

const Shedule = () => (
    <div className="sidewidget widgetnopadd">
        <div className="sidebarimg">
            <img src={Sidebar1} alt="sidebar" />
            <a href="https://www.facebook.com/wrtsdavie/" className="wrtsbtn yellowbtn inlinebtn"
            target='_blank'>
                VIEW OUR SCHEDULE
            </a>
        </div>
    </div>
);

export default Shedule;
